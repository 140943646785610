/***********************************************
* Global layout
************************************************/
html {
  overflow-y: scroll;
  background: $pam-prussian-blue;
  color: $pam-white;
  body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}
img {
  max-width: 100%;
  height: auto;
}

/***********************************************
* Icons
************************************************/
.icon-container {
  display: inline-block;
  vertical-align: middle;
  background: $pam-linen;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border-bottom: none;

  &.icon-outline {
    background: none;
    border: 2px solid $pam-fallow;
    .icon {
      margin: -2px 0 0 -2px;
    }
    &:hover {
      border: 2px solid $pam-fallow;
    }
  }

  //Icon container in btn-link
  .btn-link & {
    margin: -10px 0 -6px;
    .icon {
      margin: 0;
    }
  }

  //Icon container in nav link (global navigation manly)
  .nav-link & {
    margin: -10px 0 -6px;
  }

  /*
  //TODO: remove if not needed. So far all icons are $pam-fallow, not only the ones in containers
  .icon {
    color: $pam-fallow;
  }
  */
}

.icon {
  //initial icon styles from icomoon.io
  display: inline-block;
  width: 1em;
  height: 1em;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;

  //additional custom styles for icons bellow
  font-size: 40px;
  vertical-align: middle;
  color: $pam-fallow;

  .btn & {
    margin: -10px -4px -6px -8px;
  }
  .btn-primary & {
    color: $pam-fallow;
  }
}

/* ==========================================
Single-colored icons can be modified like so:
.icon-name {
  font-size: 32px;
  color: red;
}
========================================== */


/***********************************************
* Epi quick navigator on public pages - fix
************************************************/
ul#epi-quickNavigator {
  li {
    margin-bottom: 0;
    &:before {
      display: none;
    }
  }

  a {
    border-bottom: none;
    font-weight: normal;
  }
}

/***********************************************
* Helper for filtering cards
************************************************/
.filtered-out {
  display: none!important;
}

/***********************************************
* Show collapse elements if no js is there to open it
************************************************/
html.no-js .collapse {
  display: block!important;
}

/***********************************************
* Helper class mostly for fixing long strings in flex element (emails in narrow flex columns in footer)
************************************************/
.break-word {
  overflow-wrap: break-word;
  word-wrap: break-word;
  min-width: 1px;
}