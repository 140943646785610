/***********************************************
* Headings
************************************************/
h1, .h1 {
  font-size: calculate-rem(36px);

  @include media-breakpoint-up(lg) {
    font-size: calculate-rem(48px);
  }
}

h2, .h2 {
  font-size: calculate-rem(32px);

  main &,
  body.mceContentBody & {
    margin-top: 2rem;
  }

  .global-footer & {
    color: $pam-fallow;
    margin-bottom: 1rem;
  }

  @include media-breakpoint-up(lg) {
    font-size: calculate-rem(36px);
  }
}

h3, .h3 {
  font-size: calculate-rem(24px);

  main &,
  body.mceContentBody &{
    margin-top: 2rem;
  }
}

h4, .h4 {
  font-family: $pam-din-next;
  font-weight: 700;
  font-size: calculate-rem(14px);
  text-transform: uppercase;

  main &,
  body.mceContentBody & {
    margin-top: 2rem;
  }
}

/***********************************************
* Global text styles
************************************************/
a {
  font-weight: bold;
  border-bottom: 1px solid $pam-fallow;
  &:hover {
    border-bottom-color: $pam-soft-amber;
  }
  &:focus {
    background: $pam-whisper;
  }
}

.lead {
  font-size: calculate-rem(20px);
  font-weight: bold;

  @include media-breakpoint-up(lg) {
    font-size: calculate-rem(24px);
  }
}

.ff-galliard {
  font-family: $pam-galliard;
}
.ff-din-next {
  font-family: $pam-din-next;
}

ul {
  list-style-type: none;
  padding: 0 0 0 20px;

  li {
    margin-bottom: 0.5rem;
    position: relative;

    &:before {
      content: ' ';
      display: inline-block;
      width: 0;
      height: 0;
      border-top: 5px solid transparent;
      border-bottom: 5px solid transparent;
      border-left: 10px solid $pam-fallow;
      position: absolute;
      top: 6px;
      left: -20px;
    }
  }

  &.list-unstyled {
    li {
      margin: 0;
      position: static;

      &:before {
        display: none;
      }
    }
  }
}

ol {
  padding: 0 0 0 20px;

  li {
    margin-bottom: 0.5rem;
  }
}


/***********************************************
* Forms
************************************************/
label {
  font-family: $pam-din-next;
  font-weight: bold;
  margin-bottom: calculate-rem(3px);
}

.form-control {
  font-family: $pam-din-next;
  padding-top: calculate-rem(13px);
  padding-bottom: calculate-rem(9px);

}

.form-text {
  font-size: calculate-rem(14px);
  font-family: $pam-din-next;
}

.custom-select {
  @include transition($btn-transition);
  @include hover-focus-active {
    background-color: $pam-cobalt;
    &::-ms-value {
      background-color: $pam-cobalt;
      color: $pam-white;
    }
  }

  padding-top: calculate-rem(13px);
  padding-bottom: calculate-rem(9px);
  font-family: $pam-din-next;
  font-weight: bold;
  cursor: pointer;
}

/*validation styles*/
.input-validation-error {
  @extend .is-invalid;
}
.field-validation-error {
  @extend .invalid-feedback;
  font-family: $pam-din-next;
}
.validation-message {
  font-family: $pam-din-next;
  position: relative;
  color: $pam-venetian-red;

  &:before {
    content: ' ';
    position: absolute;
    top: 0;
    left: -10px;
    bottom: 0;
    border-right: 2px solid $pam-venetian-red;
  }

  a {
    color: $pam-venetian-red;
    border-bottom-color: $pam-venetian-red;
  }

  ul li:before {
    border-left-color: $pam-venetian-red;
  }
}

/***********************************************
* Buttons
************************************************/
.btn {
  font-family: $pam-din-next;
  font-weight: bold;
  padding-top: calculate-rem(13px);
  padding-bottom: calculate-rem(9px);
}
.btn-block {
  white-space: normal;
}
/*primary button*/
.btn-primary {
  @include hover-focus {
    background-color: $pam-cobalt;
  }
  &.disabled,
  &:disabled {
    color: $pam-suva-grey;
    background-color: $pam-very-light-grey;
    border-color: $pam-very-light-grey;
    .icon {
      color: $pam-suva-grey;
    }
  }
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    background-color: $pam-navy-blue;
  }
}

/*button toggle navigation*/
.btn-toggle-nav {
  padding-right: 0;
  padding-left: 0;
  .icon-menu {
    display: none;
  }
  &.collapsed {
    .icon-menu {
      display: inline-block;
    }
    .icon-close {
      display: none;
    }
  }
}


/*button language*/
.btn-lang {
  @include hover-focus {
    background-color: $pam-cobalt;
    color: $pam-soft-amber;
  }

  background: $pam-prussian-blue;
  color: $pam-soft-amber;
  font-size: calculate-rem(14px);
  text-transform: uppercase;
  padding: calculate-rem(3px) calculate-rem(15px) 0;

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    background-color: $pam-navy-blue;
  }

  &.selected {
    /*
    @include hover-focus {
      background-color: transparent;
      color: transparent;
    }
    */
    background: transparent;
    border-color: transparent;
    color: $pam-nero;
    &:before {
      content: " ";
      display: inline-block;
      width: 6px;
      height: 13px;
      border-right: 2px solid $pam-nero;
      border-bottom: 2px solid $pam-nero;
      transform: rotate(45deg);
      margin-right: 5px;
    }

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {
      background-color: transparent;
    }
  }

}

/*button search*/
.btn-search {
  background: transparent;
  padding-right: 0;
  padding-left: 0;
  .icon {
    margin-left: 0;
    margin-right: 0;
    color: $pam-fallow;
  }
}


/***********************************************
* Images
************************************************/
.logo {
  width: 150px;
  height: 37px;

  @include media-breakpoint-up(lg) {
    width: 310px;
    height: 77px;
  }
}

.logo-swan {
  width: 100px;
  height: 100px;
}

.logo-swan-small {
  width: 22px;
  height: 22px;
}

.img-lead {
  padding-bottom: 15px;
  @include media-breakpoint-up(lg) {
    padding-bottom: 30px;
  }
}

/***********************************************
* List groups
************************************************/
.list-group-item {
  font-family: $pam-din-next;
  font-weight: bold;
  padding-top: calculate-rem(14px);
  padding-bottom: calculate-rem(10px);

  &.active:before {
    content: " ";
    display: inline-block;
    width: 6px;
    height: 13px;
    border-right: 2px solid $pam-nero;
    border-bottom: 2px solid $pam-nero;
    transform: rotate(45deg);
    margin-right: 10px;
  }

}

/***********************************************
* Input search
************************************************/
.input-search {
  position: relative;

  .form-control {
    padding-right: 42px;
  }
  .btn-search {
    position: absolute;
    top: 0;
    right: 0;
  }

}

/***********************************************
* Definition list inline
************************************************/
dl.inline {
  @include make-row;
  border-bottom: 1px solid $pam-soft-amber;
  margin: 2rem 0;

  dt, dd {
    @include make-col-ready;
    @include make-col(7);
    border-top: 1px solid $pam-soft-amber;
    font-weight: normal;
    padding: 5px 5px 5px 0;
  }

  dd {
    @include make-col(5);
    padding: 5px 0 5px 5px;
    margin-bottom: 0;
    text-align: right;
  }
}

