/***********************************************
* Themes for sections
************************************************/
.theme-light {
  background: $pam-white;
}
.theme-dark {
  background: $pam-linen;
}

/***********************************************
* Global header
************************************************/
.global-header {
  @include clearfix;

  padding: 15px 0 0;
  background: $pam-white;
  border-bottom: 1px solid $pam-soft-amber;

  .logo-link {
    float: left;
    margin: 0 0 15px -10px;
  }

  .btn-toggle-nav {
    float: right;
    margin: -5px 0 0 0;
  }

  .nav-container {
    clear: both;
  }

  @include media-breakpoint-up(sm){
    .logo-link {
      margin-left: -15px;
    }
  }

  @include media-breakpoint-up(lg){
    padding: 0;
    height: 150px;

    .logo-link {
      margin: 60px 0 0 -35px;
    }

    .btn-toggle-nav {
      display: none;
    }

    .nav-container {
      clear: none;
      @include make-container();
      @include make-container-max-widths();
      position: relative;
      &.collapse {
        display: block;
      }
    }

    .global-nav {
      position: absolute;
      top: 97px;
      right: 15px;
    }

    .util-nav {
      position: absolute;
      top: 15px;
      right: 15px;
    }

  }
}

/***********************************************
* Global footer
************************************************/
.global-footer {
  background: $pam-prussian-blue;
  color: $pam-white;
  padding: 15px 0 0;

  a {
    color: $pam-white;
    &:hover {
      color: $pam-fallow;
      border-bottom-color: $pam-shadow;
    }
    &:focus {
      background: $pam-tangaroa;
    }
  }

  .footer-top {
    border-bottom: 1px solid $pam-shadow;
    padding-bottom: 15px;
    margin-bottom: 2rem;
    .logo-link {
      margin-left: -10px;
    }
  }

  section {
    margin-bottom: 1rem;


    &.financeportalen-text {
      font-family: $pam-din-next;
    }

  }

  @include media-breakpoint-up(sm){
    .footer-top .logo-link {
      margin-left: -15px;
    }
  }

  @include media-breakpoint-up(lg){
    padding-top: 30px;

    .footer-top {
      padding-bottom: 30px;
      margin-bottom: 3rem;
      .logo-link {
        margin-left: -35px;
      }
    }

    section {
      margin-bottom: 30px;

      &.financeportalen-text {
        text-align: right;
        margin-bottom: 54px;
      }
    }

  }
}

/***********************************************
* Cookie warning
************************************************/
.cookie-warning {
  border-top: 1px solid $pam-soft-amber;
  background: $pam-linen;

  &.fixed {
    position: fixed;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1000;
    max-height: 100vh;
    overflow: auto;
  }

  .container {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  @include media-breakpoint-up(md){
    *:last-child {
      margin-bottom: 0;
    }
  }
}

/***********************************************
* Page headers
************************************************/
.page-header {
  border-bottom: 1px solid $pam-soft-amber;
  padding: 15px 0;

  .logo-swan {
    display: block;
    margin: 0 auto 1rem;
  }

  h1 {
    text-align: center;
  }

  .lead {
    text-align: center;
  }

  @include media-breakpoint-up(lg){
    padding: 30px 0;

    .container {
      position: relative;
    }

    .logo-swan {
      position: absolute;
      top: 0;
      right: 15px;
      margin: 0;
      height: auto;
    }
  }
}

/***********************************************
* Header tabs
************************************************/
.row.header-tabs {
  margin: 0;
}

.header-tab {
  background: $pam-prussian-blue;
  text-align: center;

  &:not(.active) {
    margin: 15px 0px -15px;
    border: solid $pam-floral-white;
    border-width: 0px 2px;
    p {
      margin-top: 1rem;
    }
    a {
      font-family: $pam-din-next;
      color: white;
      &:hover {
        color: $pam-fallow;
        border-color: $pam-fallow;
      }
    }
  }

  &.active {
    margin: 0px 0px -16px;
    background: white;
    border: solid $pam-soft-amber;
    border-width: 1px 1px 0px 1px;
  }

  h1 {
    color: $pam-shadow;
    font-size: calculate-rem(18px);
    font-weight: bold;
    vertical-align: middle;
    margin-top: 1.75rem;
  }

  @include media-breakpoint-up(lg) {
    &:not(.active) {
      margin: 25px 0px -30px;
      border-width: 0px 5px;
    }
    &.active {
      margin: 0px 0px -31px;
    }
    h1 {
      font-size: calculate-rem(24px);
      margin-top: 2rem;
    }
  }
}


/***********************************************
* Large screen search
************************************************/
.lg-search {
  background: $pam-linen;
  border-bottom: 1px solid $pam-soft-amber;

  .container {
    position: relative;
  }

  .btn-close {
    padding-left: 0;
    padding-right: 0;
    position: absolute;
    top: 0;
    right: 15px;
  }

  @include media-breakpoint-down(md){
    &.collapse.show {
      display: none;
    }
  }

  @include media-breakpoint-up(lg){
    form {
      padding: 30px 0;
    }
  }
}

/***********************************************
* Block - used as additional class
************************************************/
.block {
  border-bottom: 1px solid $pam-soft-amber;
  padding: 15px 0;

  &.employees-search {
    border: none;
    padding-bottom: 0;
  }

  .block-header {
    margin-bottom: 1rem;

    h2, .h2 {
      text-align: center;
      margin-top: 0;
    }

    .lead {
      font-size: inherit;
      font-weight: normal;
      text-align: center;
    }
  }


  @include media-breakpoint-up(lg){
    padding: 30px 0;
  }
}

/***********************************************
* Close Block - used as additional class
************************************************/

.close-block {
  display: none;
}

.expandable-content .close-block {
  display: block;
}

/***********************************************
* Message
************************************************/
.message {
  padding: 30px 0 15px;
  background: $pam-prussian-blue;
  color: $pam-white;

  &.message-notification {
    background: $pam-christalle;
  }

  &.message-success {
    background: $pam-crusoe;
  }

  figure {
    text-align: center;
  }

  h2 {
    margin-top: 0;
  }

  a {
    color: $pam-white;
    &:focus {
      background: transparent;
    }
  }

  @include media-breakpoint-up(md){
    position: relative;
    figure {
      position: absolute;
      top: -6px;
      left: -65px;
    }
  }

  @include media-breakpoint-up(lg){
    padding: 60px 0 45px;
  }
}

/***********************************************
* Rich-text-section
************************************************/
.rich-text-content {

  .blockquote {
    margin-left: -15px;
    margin-right: -15px;
  }

  .figure.figure-large {
    margin-left: -15px;
    margin-right: -15px;
    .figure-caption {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  .figure.figure-small {
    max-width: 255px;
  }

  .carousel {
    margin-left: -15px;
    margin-right: -15px;
  }

  @include media-breakpoint-up(md){
    .blockquote {
      $offset: percentage(90/510);
      margin-left: -$offset;
      margin-right: -$offset;
      padding-left: $offset;
      padding-right: $offset;
    }

    .figure.figure-large {
      $offset: percentage(90/510);
      margin-left: -$offset;
      margin-right: -$offset;
      .figure-caption {
        padding-left: 0;
        padding-right: 0;
      }
    }

    .figure.figure-small {
      max-width: 50%;
    }

    .carousel {
      $offset: percentage(90/510);
      margin-left: -$offset;
      margin-right: -$offset;
      .carousel-item-content {
        width: 66.6667%;
      }
    }

    .article-authors {
      $offset: percentage(90/510);
      margin-top: 30px;
      margin-left: -$offset;
      margin-right: -$offset;
    }
  }

  @include media-breakpoint-up(lg){
    padding-bottom: 15px;

    .figure.figure-large {
      $offset: percentage(200/530);
      margin-left: -$offset;
      margin-right: -$offset;
    }
  }

  @include media-breakpoint-up(xl){
    .figure.figure-large {
      $offset: percentage(285/540);
      margin-left: -$offset;
      margin-right: -$offset;
    }

  }
}

/***********************************************
* Carousel full used as full width section
************************************************/
.carousel-full {
  border-bottom: 1px solid $pam-soft-amber;

  .carousel {
    margin: 0;
  }

  @include media-breakpoint-up(md){
    .carousel-item {
      max-height: 600px;
    }

    .carousel-item-content {
      width: 460px;
      left: 50%;
      margin-left: -115px;
    }
  }

  @include media-breakpoint-up(lg){
    .carousel-item-content {
      margin-left: 5px;
    }
  }

  @include media-breakpoint-up(xl){
    .carousel-item-content {
      margin-left: 95px;
    }
  }
}

/***********************************************
* contact form
************************************************/
.contact-form {

  .btn-close {
    padding-left: 0;
    padding-right: 0;
  }

  .btn-primary {
    display: block;
    width: 100%;
    white-space: normal;
  }

  @include media-breakpoint-up(sm){
    .btn-primary {
      display: inline-block;
      width: auto;
    }
  }
}

/***********************************************
* contact block
************************************************/
.contact-block {

  ul.contact-office-list {

    @include media-breakpoint-up(lg){
      display: flex;
      flex-direction: row;
      li {
        padding-right: 30px;
        width: 30%;
        &:first-child {
          width: 40%;
        }
        &:last-child {
          padding-right: 0px;
        }
      }
    }
    @include media-breakpoint-up(xl){
      li {
        padding-right: 60px;
        &:last-child {
          padding-right: 0px;
        }
      }
    }
  }
}

/***********************************************
* Teasers
************************************************/
.teaser-container {
  padding: 30px 0 15px;

  @include media-breakpoint-up(lg){
    padding: 60px 0 30px;
  }
}

/***********************************************
* Shortcuts
************************************************/
.shortcuts {

  .btn {
    display: block;
    width: 100%;
    white-space: normal;
    margin-bottom: 15px;
  }

  @include media-breakpoint-up(lg){
    padding-bottom: 15px;
  }
}

/***********************************************
* Documents
************************************************/
.documents {

}

/***********************************************
* Fund groups
************************************************/
.fund-groups {
  padding-bottom: 0;

  article {
    margin: 0 0 15px;
  }

  figure {
    text-align: center;
    img {
      width: 100px;
      height: 75px;
    }
  }

  @include media-breakpoint-up(lg){
    padding-bottom: 15px;

    article {
      margin-bottom: 30px;
    }
  }

}

/***********************************************
* Navigation block
************************************************/
.navigation {
  .btn-block {
    margin-bottom: 30px;
  }
}

/***********************************************
* Featured funds
************************************************/
.featured-funds {

}

/***********************************************
* Employee CV
************************************************/
.employee-cv {

}

/***********************************************
* Employee funds
************************************************/
.employee-funds {
  padding-bottom: 0;
}

/***********************************************
* Bottom illustration
************************************************/
.bottom-illustration {
  background: $pam-floral-white;
  text-align: center;
  margin-top: -1px;
}

.theme-light + .bottom-illustration {
  background: $pam-white;
}
.theme-dark + .bottom-illustration {
  background: $pam-linen;
}

/***********************************************
* Documents list for fund
************************************************/
.docs-list-fund {
  padding: 30px 0;

  .block-header {
    .lead {
      text-align: left;
      font-size: calculate-rem(14px);
    }
  }
  .docs-list-group {
    .lead {
      font-size: 1rem;
    }
  }

  @include media-breakpoint-up(lg){
    padding: 60px 0;

    .block-header {
      margin-bottom: 2rem;
    }
  }
}

/***********************************************
* Documents list for other documents
************************************************/
.docs-list-other {
  padding: 30px 0;

  .block-header {
    .lead {
      text-align: left;
      font-size: calculate-rem(14px);
    }
  }

  @include media-breakpoint-up(lg){
    padding: 60px 0;
    .block-header {
      margin-bottom: 2rem;
    }
  }
}

/***********************************************
* Graph/Diagram
************************************************/

.diagram {

  .diagram-info {
    background: $pam-pattens-blue;
    padding: 15px;
    margin-bottom: 15px;

    @include media-breakpoint-up(lg) {
      padding: 30px;
      margin-bottom: 30px;
    }

    @include media-breakpoint-up(xl) {
      padding: 30px 60px;
      margin-bottom: 60px;
    }

    .date-box {
      display: inline-block;
      float: left;
      margin-bottom: 5px;

      input.form-control {
        display: inline-block;
        width: 110px;
        line-height: 1;
        padding-top: 0.85rem;
        padding-bottom: 0.85rem;
      }

      @include media-breakpoint-up(xl) {
        margin-right: 10px;
      }
    }

    p {
      margin-bottom: 0.5rem;
      font-size: calculate-rem(14px);
    }

    .diagram-label {
      margin-top: 0.5rem;
      margin-bottom: 0.25rem;
      font-family: "DIN Next W01", sans-serif;
      font-weight: 700;
      font-size: 1rem;
    }
  }

  .product-list-nav {

    @include media-breakpoint-up(md) {
      display: inline-block;
      float: left;
      position: relative;
      margin-right: 15px;
      margin-bottom: 5px;

      .class-list {
        z-index: 2;
        position: absolute;
        left: 0;
        right: 0;
      }
    }

    > a {
      color: $pam-soft-amber;

      &:focus {
        background: $pam-cobalt $custom-select-indicator no-repeat right $custom-select-padding-x center;
        background-size: $custom-select-bg-size;
      }

      &.custom-select {
        height: auto;
      }
    }
  }

  .interval-buttons {
    @include media-breakpoint-down(lg) {
      clear: both;
    }

    @include media-breakpoint-up(xl) {
      display: inline-block;
      float: left;
      margin: 31px 0 0 0;
    }
  }

  .period-list {
    @extend .list-unstyled;

    li {
      display: inline-block;
      padding: 5px 5px 0 0;

      a {
        color: $pam-soft-amber;
        padding-left: 1rem;
        padding-right: 1rem;
      }
    }
  }
}

.graph {
  .graph-legend {
    margin-top: 15px;
    font-size: calculate-rem(14px);

    @include media-breakpoint-up(lg) {
      margin-top: 30px;
    }
  }
}