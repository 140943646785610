/**************************************
* jQueryUI datepicker customization
***************************************/
.ui-datepicker {
  background: #fff;
  border: 1px solid $pam-soft-amber;
  display: block;
  margin-top: 2px;
  z-index: 6 !important;
}

.ui-datepicker .ui-state-disabled {
  opacity: .35;
  filter: Alpha(Opacity=35);
}

.ui-datepicker .ui-datepicker-header {
  background: $pam-fallow;
  color: #fff;
}

.ui-datepicker .ui-datepicker-title {
  line-height: normal;
}

.ui-datepicker .ui-datepicker-year {
  color: #222;
  padding: 4px 0 0;
}

.ui-datepicker .ui-datepicker-calendar th {
  font-weight: normal;
}

.ui-datepicker .ui-datepicker-calendar .ui-state-default {
  border: 1px solid $pam-very-light-grey;
  color: $pam-suva-grey;

  &:hover {
    background: $pam-linen;
  }
}

.ui-datepicker .ui-datepicker-calendar .ui-state-active,
.ui-datepicker .ui-datepicker-calendar .ui-state-highlight {
  background: $pam-fallow;
  color: #fff;
}

.ui-datepicker .ui-datepicker-calendar .ui-datepicker-today .ui-state-highlight {
  background: white;
  color: $pam-prussian-blue;
}

.ui-datepicker .ui-datepicker-prev,
.ui-datepicker .ui-datepicker-next {
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  top: 0;
  border: none;
  &:hover {
    border: none;
  }
}

.ui-datepicker .ui-datepicker-prev {
  left: 0;
}

.ui-datepicker .ui-datepicker-next {
  right: 0;
}

.ui-datepicker .ui-datepicker-prev:before,
.ui-datepicker .ui-datepicker-next:before {
  font-size: 20px;
  font-weight: bold;
  content: '<';
  color: #fff;
}
.ui-datepicker .ui-datepicker-next:before {
  content: '>';
}

/* triming empty datepicker div at the bottom of the page */
#ui-datepicker-div {
  display: none;
}
