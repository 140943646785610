/***********************************************
* Images
************************************************/
.logo-link {
  display: block;
  border-bottom: none;
}

/***********************************************
* Global navigation
************************************************/
.global-nav {

  background: $pam-floral-white;

  .nav-item {
    border-top: 1px solid $pam-soft-amber;
    margin: 0;

    .nav-link {
      font-family: $pam-din-next;
      font-size: calculate-rem(24px);
      font-weight: normal;
      border-bottom: none;
      text-align: center;
      padding: 7px 15px;
    }
    &:before {
      display: none;
    }
  }

  .search-item {
    .nav-link {
      display: none;
    }
    form {
      padding: 7px 15px;
    }
  }


  @include media-breakpoint-up(lg){
    background: none;

    .nav-item {
      border-top: none;
      margin-left: 30px;
      &:first-child {
        margin-left: 0;
      }
      .nav-link {
        padding: 7px 0;
        &.active {
          border-bottom: 2px solid $pam-soft-amber;
        }
      }
    }

    .search-item {
      .nav-link {
        display: block;
      }
      form {
        display: none;
      }
    }


  }

  @include media-breakpoint-up(xl){
    .nav-item {
      margin-left: 50px;
    }
  }

}


/***********************************************
* My page nav
************************************************/
.mypage-nav {
  border-top: 1px solid $pam-soft-amber;

  @include media-breakpoint-up(lg){
    border-top: none;
    border-left: 1px solid $pam-soft-amber;
    padding-left: 15px;
    margin-left: 15px;

    .btn {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

/***********************************************
* Country nav
************************************************/
.country-nav {
  border-top: 1px solid $pam-soft-amber;

  .btn {
    @include caret(down);
  }

  .country-list  {
    text-align: center;
    .list-group {
      margin: 0 30px 15px;
    }
  }

  @include media-breakpoint-up(lg){
    border-top: none;
    position: relative;

    .btn {
      padding-left: 0;
      padding-right: 0;
    }

    .country-list  {
      position: absolute;
      top: 50px;
      left: 0;
      right: 0;
      z-index: $zindex-dropdown;


      .list-group {
        margin: 0;
      }
    }

  }
}

/***********************************************
* Language nav
************************************************/
.language-nav {

  text-align: center;
  margin-bottom: 15px;

  @include media-breakpoint-up(lg){
    margin: 12px 0 0 15px;
  }
}

/***********************************************
* Media block with icons
************************************************/
.media {
  > .icon,
  > .icon-container {
    margin: -7px 7px 0 0;
  }
}

/***********************************************
* Link list
************************************************/
ul.link-list {
  @extend .list-unstyled;

  border-top: 1px solid $pam-soft-amber;

  li {
    margin: 0;
    border-bottom: 1px solid $pam-soft-amber;
  }

  a {
    border-bottom: none;
    font-weight: bold;
    font-family: $pam-din-next;
    font-size: calculate-rem(18px);
    display: block;
    padding: 12px 35px 8px 0;
    position: relative;

    .icon {
      position: absolute;
      top: 3px;
      right: 0;
    }
  }

  .global-footer & {
    border-top-color: $pam-shadow;

    li {
      border-bottom-color: $pam-shadow;
    }

    a {
      padding-top: 10px;
      padding-bottom: 6px;
      font-size: calculate-rem(16px);
      .icon {
        top: 0;
      }
    }
  }

}

/***********************************************
* Social media links
************************************************/
ul.social-links {
  @extend .list-unstyled;

  font-family: $pam-din-next;

  li {
    display: inline-block;
    margin: 0 10px 15px 0;
  }

  a {
    display: inline-block;
  }

  .newsletter-link {
    display: inline-flex;
    align-items: center;
    border: none;

    .icon-container {
      margin-right: 10px;
    }

    .fake-link {
      border-bottom: 1px solid $pam-fallow;
    }
    &:hover .fake-link {
      border-bottom-color: $pam-shadow;
    }
  }

}

/***********************************************
* Breadcrumb
************************************************/
.breadcrumb {
  font-family: $pam-din-next;
  font-weight: bold;
  font-size: 14px;

  @include media-breakpoint-up(lg){
    font-size: 16px;
  }
}

/***********************************************
* Byline
************************************************/
.byline {
  font-family: $pam-din-next;
  font-weight: bold;
  text-align: center;
  font-size: calculate-rem(14px);
  display: flex;
  flex-direction: column;
  align-items: center;

  @include media-breakpoint-up(lg){
    flex-direction: row;
    align-items: start;
    justify-content: center;

    .date + .author:before {
      content: "|";
      color: $pam-fallow;
      padding-left: 5px;
      padding-right: 5px;
    }
  }
}

/***********************************************
* Blockquote
************************************************/
.blockquote {
  background: $pam-linen;
  padding: 15px;
  font-size: calculate-rem(18px);
  font-weight: bold;
  color: $pam-prussian-blue;

  .theme-light &,
  .theme-dark & {
    background: $pam-floral-white;
  }

  .blockquote-footer {
    font-family: $pam-din-next;
    font-weight: bold;
    font-size: calculate-rem(14px);
    text-transform: uppercase;
  }

  @include media-breakpoint-up(lg){
    padding: 30px;
    font-size: calculate-rem(24px);
  }
}

/***********************************************
* Table
************************************************/
.table {
  th, td {
    border-top: none;
    border-bottom: 1px solid #D5C3AA;
    font-family: $pam-din-next;
  }

  thead {
    th, td {
      border-bottom: 1px solid #D5C3AA;
      padding-top: 0.5rem;
      padding-bottom: 0.25rem;
    }
  }

  tbody {
    th, td {
      background: $pam-linen;
    }
    a {
      font-size: calculate-rem(18px);
    }
  }

  @include media-breakpoint-down(lg) {
    &.table-simplified-mobile {
      display: block;

      thead, tbody, th, td, tr {
        display: block;
      }

      thead tr {
        @include sr-only;
      }

      tbody tr {
        border-bottom: 1px solid #D5C3AA;
      }

      tbody td,
      tbody th {
        border-bottom: none;
        padding-top: 3px;
        padding-bottom: 3px;
        text-align: left!important;
        /*display data-th attribute if present*/
        &:before {
          display: block;
          content: ""attr(data-th)"";
          font-weight: bold;
        }
      }

    }

  }
}

/***********************************************
* Table fund group
************************************************/

table.table-fund-group {
  .column-1 {
    a {
      margin-right: 5px;
    }
  }

  .column-2 {
    @extend .d-none;
    @extend .d-lg-table-cell;
    text-align: center;
    font-weight: bold;
  }

  .column-3 {
    @extend .d-none;
    @extend .d-xl-table-cell;
  }

  .column-4 {
    @extend .d-none;
    @extend .d-lg-table-cell;
    text-align: right;
  }
   tbody .column-4 {
    white-space: nowrap;
  }

  .column-5 {
    text-align: right;
  }
  .column-6 {
    text-align: right;
  }

  .column-7 {
    @extend .d-none;
    @extend .d-md-table-cell;
    text-align: right;
  }

  .column-8 {
    @extend .d-none;
    @extend .d-md-table-cell;
    text-align: right;
  }
}

/***********************************************
* Related links
************************************************/
.related-links {
  margin: 3rem 0;
}

/***********************************************
* Figure
************************************************/
.figure {
  display: block;
}

/***********************************************
* Carousel
************************************************/
.carousel {
  margin: 0 0 1rem;

  // Dirty fix for Firefox 58/59 if needed
  // http://jira.laboremus.no/browse/PAM-210
  // https://github.com/twbs/bootstrap/issues/24657
  /*
  .carousel-item {
    transition: transform 0.5s ease;
    backface-visibility: visible;
  }
  */

  .carousel-item-content {
    background: $pam-prussian-blue;
    color: $pam-white;
    padding: 15px;
    position: relative;

    a {
      color: $pam-white;
      &:hover {
        color: $pam-fallow;
        border-bottom-color: $pam-shadow;
      }
      &:focus {
        background: $pam-tangaroa;
      }
    }
  }

  .carousel-item-content-text {
    font-size: calculate-rem(13px);
    min-height: 150px;
    h2 {
      margin: 0 0 0.5rem;
      font-family: $pam-din-next;
      font-weight: bold;
      font-size: calculate-rem(18px);
      line-height: 1.5;
    }
  }

  .carousel-item-content-navigation {
    p {
      margin: 0;
    }
    .icon-container {
      margin-right: 5px;
      &:focus {
        background: $pam-linen;
      }
    }
  }

  @include media-breakpoint-up(sm){
    .carousel-item-content-text {
      min-height: 100px;
    }
  }

  @include media-breakpoint-up(md){
    .carousel-item-content {
      padding-left: 30px;
      padding-right: 30px;
      position: absolute;
      width: 100%;
      right: 0;
      bottom: 0;
      background-color: transparentize($pam-prussian-blue, 0.1);
    }
  }

}

/***********************************************
* Teaser
************************************************/
.teaser {
  background: $pam-white;
  margin: 0 0 15px;
  border: 3px solid $pam-prussian-blue;
  padding: 15px;

  h2 {
    margin-top: 0;
    font-size: calculate-rem(18px);
    line-height: 1.5;
  }

  @include media-breakpoint-up(md){
    .figure {
      margin-bottom: 0;
    }
  }

  @include media-breakpoint-up(lg){
    padding: 30px;
    margin-bottom: 30px;
  }

  @include media-breakpoint-up(xl){
    padding: 60px;
  }
}

/***********************************************
* Document/Contact list
************************************************/
ul.document-list,
ul.contact-list,
ul.contact-office-list {
  @extend .list-unstyled;

  li {
    margin-bottom: 0.25rem;
  }

  .icon-container {
    margin-bottom: 0.5rem;
  }

  .list-link {
    margin: 0 0 0.5rem;
    overflow-wrap: break-word;
    word-wrap: break-word;
    a {
      font-family: $pam-din-next;
      font-size: calculate-rem(18px);
    }
  }

  .list-description {
    font-size: calculate-rem(14px);
    margin: 0;
  }
}

ul.document-list {
  li {
    margin-bottom: 2rem;
  }

  @include media-breakpoint-up(sm){
    &.wide {
      li {
        display: flex;
        align-items: start;
      }
      .icon-container {
        margin-right: 0.5rem;
      }
      .list-text {
        flex: 1;
        /* https://css-tricks.com/flexbox-truncated-text/ */
        min-width: 150px;
        p {
          margin-top: 0.5rem;
        }
      }
    }
  }
}

ul.contact-list,
ul.contact-office-list {
  li {
    display: flex;
    align-items: start;
  }
  .icon-container {
    margin-right: 0.5rem;
  }
  .list-text {
    flex: 1;
    min-width: 150px;
    p {
      margin-top: 0.5rem;
    }
  }
}

/***********************************************
* Achor link list
************************************************/
.anchor-links {
  @extend .list-unstyled;
  @include make-row;
  margin-top: 1rem;
  .anchor-link {
    @include make-col-ready;
    display: flex;
    flex-direction: row;

    @include media-breakpoint-up(md){
      @include make-col(4);
    }
    .icon-container {
      margin-right: 0.5rem;
    }
    .link {
      font-family: $pam-din-next;
      font-size: calculate-rem(18px);
    }
    .text {
      flex: 1;
      p {
        margin-top: 0.5rem;
      }
    }
  }
}

/***********************************************
* Card - general class for cards
************************************************/
.card {
	margin-bottom: 15px;

	@include media-breakpoint-up(lg){
		margin-bottom: 30px;
	}

  .card-body {

    .card-title,
    .card-subtitle {
      margin-top: 0;
      line-height: 1.5;
    }

    p:last-child {
      margin-bottom: 0;
    }

    @include media-breakpoint-up(sm){
      padding: 30px;
    }

  }

  img {
    width: 100%;
    margin-bottom: 0.75rem;
  }

  &.small {
    .card-body {
      padding: 15px;
    }
  }

  &.extra-small {
    .card-body {
      padding: 10px;
    }
  }

  &.wide {
    .card-body {
      @include media-breakpoint-up(xl){
        padding: 30px 60px;
      }
    }
  }

}

/***********************************************
* Card custom
************************************************/
.navigation-card,
.employee-card,
.office-card,
.manager-card {
  width: 100%;
  .card-title {
    font-size: calculate-rem(18px);
  }
  p {
    font-size: calculate-rem(16px);
  }
}

.employee-card ul.contact-list,
.office-card ul.contact-office-list {
  margin-bottom: 0;
  li:last-child {
    margin-bottom: 0;
  }
  .list-link a {
    font-size: calculate-rem(16px);
  }
}

.employee-card,
.manager-card {
  .card-title {
    margin-bottom: 0.5rem;
  }
  .card-subtitle {
    text-transform: none;
  }
  .contact-list {
    margin-top: 2rem;
  }
}

.office-card ul.contact-office-list {
  li {
    &:first-child {
      display: block;
      font-size: calculate-rem(16px);
      .list-link {
        display: none;
      }
      .list-text p {
        margin-top: 0;
      }
    }
  }
}

.fund-card {
  position: relative;
  width: 100%;
  padding-bottom: 100px;

  .card-title {
    margin-bottom: .25rem;
  }

  .card-subtitle {
    margin-bottom: .5rem;
  }

  .card-img-bottom {
    width: 150px;
    height: 112px;
    position: absolute;
    right: 0;
    bottom: 0;
    margin: 0;
  }

  .card-link {
    font-family: $pam-din-next;
    font-size: calculate-rem(18px);
  }
}

.manager-card {
  .card-body {
    display: flex;
    flex-direction: row;
  }

  img.manager-photo {
    width: 100px;
    height: 120px;
    margin: 0 10px 0 0;
  }

  .manager-details {
    padding: 10px 0;
    /* https://css-tricks.com/flexbox-truncated-text/ */
    flex: 1;
    min-width: 100px;
    overflow-wrap: break-word;
    word-wrap: break-word;

    h3 {
      font-weight: bold;
    }
  }
}

/***********************************************
* Employee ID
************************************************/
.employee-id {
  img {
    margin-bottom: 1rem;
  }
  .card-title {
    font-size: calculate-rem(24px);
    margin-bottom: 0;
  }
  .card-subtitle {
    font-family: $pam-galliard;
    font-size: calculate-rem(14px);
    line-height: inherit;
  }

  ul.contact-list {
    margin: 1rem 0 0;
  }

  @include media-breakpoint-up(lg) {
    position: relative;
    margin-bottom: 2rem;

    img {
      margin-bottom: 0;
    }

    .card {
      margin: 0;
      position: absolute;
      right: 15px;
      bottom: 15px;
      width: percentage(6/12);
    }

    .list-text {
      font-size: calculate-rem(18px);
    }
  }

  @include media-breakpoint-up(xl) {
    .card {
      width: percentage(5/12);
    }
  }
}

/***********************************************
* Fund links
************************************************/
.fund-links {
  text-align: center;
  margin-bottom: 2rem;

  .figure {
    margin-bottom: 0;
    .figure-img {
      width: 60px;
      height: 45px;
    }
  }

  h3 {
    margin-top: 0;
  }

  a {
    font-family: $pam-din-next;
    font-size: calculate-rem(18px);
  }
}

/***********************************************
* Documents list group for funds
************************************************/
.docs-list-group {
  margin: 0 0 30px;

  figure {
    text-align: center;
    img {
      width: 100px;
      height: 75px;
    }
  }

  .block-header {
    h2 {
      text-align: center;
    }
    .lead {
      text-align: left;
      font-size: 1rem;
      font-weight: normal;
    }
  }

  h3 {
    margin-top: 2rem;
  }
}

/***********************************************
* Table docs
************************************************/
table.table-docs {
  .icon {
    margin-left: -8px;
  }
}

/***********************************************
* Search result list
************************************************/
ol.search-result-list {
  @extend .list-unstyled;
  margin-bottom: 2rem;

  li {
    border-bottom: 1px solid $pam-soft-amber;
    padding-bottom: 1rem;
    margin-bottom: 1rem;

    h2 {
      margin: 0;
      a {
        font-size: calculate-rem(18px);
      }
      .icon {
        margin-left: -8px;
      }
    }
    .date {
      font-family: $pam-din-next;
      font-size: calculate-rem(14px);
      font-weight: bold;
      margin: 5px 0 -5px;
    }
    .description {
      font-size: calculate-rem(16px);
      margin: 0.5rem 0 0;
    }
    .link {
      font-size: calculate-rem(12px);
      margin: 0.5rem 0 0;
      a {
        color: $pam-nero;
        border-bottom: none;
        &:hover {
          color: $pam-cobalt;
        }
      }
    }
  }
}

/***********************************************
* Pagination for search result list
************************************************/
ul.pagination {
  @extend .list-unstyled;
  font-family: $pam-din-next;

  .page-item {
    margin: 0 2px;

    .page-link {
      font-size: calculate-rem(24px);
      border: transparent;
    }

    a {
      background: $pam-prussian-blue;
      color: $pam-fallow;
      &:hover {
        background: $pam-cobalt;
      }
    }

    &:first-child,
    &:last-child {
      .page-link {
        padding: 3px 0;
      }
    }
    // active pagination page link
    &.active {
      .page-link {
        background: $pam-suva-grey;
        color: $pam-very-light-grey;
        cursor: default;
      }
    }
    // disabled pagination page link
    &.disabled {
      .page-link {
        background: transparent;
        color: $pam-suva-grey;
        cursor: default;
        .icon {
          color: $pam-suva-grey;
        }
      }
    }
  }

}