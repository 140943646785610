//***********************************************
//* custom fonts - as implemented in old solution
//************************************************/
/*
//info from https://www.fonts.com/web-fonts/projects and https://www.fonts.com/web-projects
font-family:'ITC Galliard eText W01';
font-family:'DIN Next W01';
font-family:'DIN Next™ W01';

ITC Galliard® eText W01 Roman
------------------------------
font-family:'ITC Galliard eText W01';
font-weight:400;
font-style:normal;

ITC Galliard® eText W01 Italic
-------------------------------
font-family:'ITC Galliard eText W01';
font-weight:400;
font-style:italic;

ITC Galliard® eText W01 Bold
-----------------------------
font-family:'ITC Galliard eText W01';
font-weight:700;
font-style:normal;

ITC Galliard® eText W01 Bold Italic
------------------------------------
font-family:'ITC Galliard eText W01';
font-weight:700;
font-style:italic;



DIN Next™ W01 Condensed Medium
--------------------------------
font-family:'DIN Next W01';
font-weight:500;
font-style:normal;



DIN Next™ W01 Regular
-----------------------
font-family:'DIN Next W01';
font-weight:400;
font-style:normal;

DIN Next™ W01 Italic
---------------------
font-family:'DIN Next™ W01';
font-weight:400;
font-style:italic;

DIN Next™ W01 Medium
----------------------
font-family:'DIN Next W01';
font-weight:700;
font-style:normal;

DIN Next™ W01 Medium Italic
-----------------------------
font-family:'DIN Next™ W01';
font-weight:700;
font-style:italic;


*/


//variables for custom fonts
$pam-galliard: 'ITC Galliard eText W01', serif;
$pam-din-next: 'DIN Next W01', sans-serif;
$pam-din-next-tm: 'DIN Next™ W01' sans-serif; // used for din italics

//***********************************************
//* custom colors - color names ideas on http://www.htmlcsscolor.com/
//************************************************/
$pam-nero: #222222;
$pam-suva-grey: #919191;
$pam-very-light-grey: #CDC9C9;
$pam-whisper: #EBEAEA;
$pam-white: #FFFFFF;

//blues
$pam-prussian-blue: #002F5F;
$pam-cobalt: #014A95;
$pam-navy-blue: #0364C8;
$pam-pattens-blue: #CFEFFC;
$pam-summer-sky: #30BDF9;
$pam-tangaroa: #012244;

//browns
$pam-shadow: #8C6E45;
$pam-fallow: #BA9C73;
$pam-soft-amber: #D5C3AA;
$pam-linen: #F6EFE5;
$pam-floral-white: #FDFAF6;

//others
$pam-venetian-red: #AE0709;
$pam-crusoe: #085423;
$pam-christalle: #3C0F5A;


//***********************************************
//* bootstrap variables
//************************************************/
// Color system
$primary: $pam-prussian-blue;

// Options
$enable-rounded: false;
$enable-print-styles: false;

// Body
$body-bg: $pam-floral-white;
$body-color: $pam-nero;

// Links
$link-color: $pam-prussian-blue;
$link-hover-color: $pam-cobalt;
$link-hover-decoration: none;

// Fonts
$font-family-base: $pam-galliard;
$headings-font-family: $pam-din-next;

$blockquote-small-color: $pam-nero;

$hr-border-color: $pam-fallow;

  // Buttons + Forms
$input-btn-padding-y: (11/16) * 1rem; // 11px
$input-btn-padding-x: (30/16) * 1rem; // 30px

// Forms
$input-padding-x: (12/16) * 1rem; // 12px
$input-border-color: $pam-soft-amber;
$input-focus-border-color: $pam-cobalt;
$input-placeholder-color: $pam-suva-grey;

$custom-select-color: $pam-white;
$custom-select-bg: $pam-prussian-blue;
$custom-select-indicator-color: $pam-white;

// Form validation
$form-feedback-font-size: (14/16) * 1rem; // 14px
$form-feedback-invalid-color: $pam-venetian-red;

// Navs
$nav-link-padding-y: (15/16) * 1rem; // 15px
$nav-link-padding-x: (15/16) * 1rem; // 15px

// Cards
$card-border-color: $pam-soft-amber;

// List group
$list-group-border-color: $pam-soft-amber;
$list-group-item-padding-x: 15px;
$list-group-active-color: $pam-nero;
$list-group-active-bg: $pam-white;
$list-group-active-border-color: $pam-soft-amber;

// Figures
$figure-caption-font-size: (13/16) * 1rem; // 13px
$figure-caption-color: inherit;

// Breadcrumbs
$breadcrumb-padding-y: 0;
$breadcrumb-padding-x: 0;
$breadcrumb-item-padding: 5px;
$breadcrumb-bg: transparent;
$breadcrumb-divider-color: $pam-shadow;
$breadcrumb-active-color: $pam-shadow;

//*******************************************
// Custom functions
//*******************************************
@function calculate-rem($size) {
  $remSize: $size / 16px;
  @return #{$remSize}rem;
}
